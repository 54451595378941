import React from 'react';
import {createRoot} from 'react-dom/client';
import RecommendationSlider from '@personalization/scripts/components/RecommendationSlider.jsx';
import WishlistHeartRecoWrapper from '../../components/WishlistHeartRecoWrapper.jsx';
import JshModule from '@core/scripts/helper/jsh-module';

const {moduleScope, config} = JshModule('wishlist/recommendations');

const {pageViewId, ajaxSwiperData, lazyloadingPixelDistance, sliderType} = config;
const {paramString, dataUrl} = ajaxSwiperData;

const paramStringWithPageViewId = pageViewId ? paramString + '&pageViewId=' + pageViewId : paramString;

createRoot(moduleScope).render(
    <RecommendationSlider
        className="recommendations"
        paramString={paramStringWithPageViewId}
        dataUrl={dataUrl}
        sliderType={sliderType}
        lazyloadingPixelDistance={lazyloadingPixelDistance}
    >
        <WishlistHeartRecoWrapper/>
    </RecommendationSlider>
);

